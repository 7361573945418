@font-face {
    font-family: 'cyberway_ridersregular';
    src: url('/src/components/Tronitron/cyberwayriders-lg97d-webfont.woff2') format('woff2'),
         url('/src/components/Tronitron/cyberwayriders-lg97d-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.tronitron-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  justify-content: center;
}

.tronitron-wrapper h3 {
  margin: 8px;
}

.introduction {
  text-align: justify;
}

.btn-wrapper {
  display: flex;
}

.btn-wrapper button {
  margin: 8px;
}

.avatar-grid {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 150px;
}

.avatar-text-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.avatar-text-wrapper h1 {
  font-family: 'cyberway_ridersregular', Arial, sans-serif;
  font-size: 82px;
  letter-spacing: 5px;
}

.avatar-text-wrapper h2 {
  font-family: 'cyberway_ridersregular', Arial, sans-serif;
  font-size: 32px;
  letter-spacing: 3px;
}

.avatar-social {
  width: 100px;
  margin: 0 auto;
  display: flex;
  font-size: 32px;
  justify-content: space-around;
  align-content: center;
}

@media (max-width: 450px) {
  .avatar-grid {
    flex-direction: column;
    padding: 32px 0;
  }

  .avatar-canvas {
    border: 1px solid #cacaca;
    margin-top: 16px;
    border-radius: 4px;
  }

  .avatar-canvas Canvas {
    height: 15%;
  }
}

.tron-linkedin.light {
  color: black;
}

.tron-linkedin.dark {
  color: white;
}

.tron-linkedin.light:hover {
  color: black;
}

.tron-linkedin.dark:hover {
  color: white;
}

.tron-linkedin-control {
  animation: rotate 3s ease forwards;
  animation-fill-mode: none;
}

.tron-linkedin-control:hover {
  transition: all 0.3s;
  transform: translateY(-5px);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

.tron-github.light {
  color: black;
}

.tron-github.dark {
  color: white;
}

.tron-github.light:hover {
  color: black;
}

.tron-github.dark:hover {
  color: white;
}

.tron-github-control {
  animation: rotateGit 3s ease forwards;
  animation-fill-mode: none;
}

.tron-github-control:hover {
  transition: all 0.3s;
  transform: translateY(-5px);
}

@keyframes rotateGit {
  from {
    transform: rotate(-0deg);
  }
  to {
    transform: rotate(-1080deg);
  }
}

.tron-github:hover {
  color: black;
}

.epictetus-wrapper {
  display: flex;
  margin-bottom: 150px;
}

.epictetus-wrapper div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.epictetus-wrapper div h2 {
  font-family: 'cyberway_ridersregular', Arial, sans-serif;
  font-size: 32px;
  letter-spacing: 5px;
}

.epictetus-wrapper img {
  border: 1px solid #cacaca;
}

.introduction {
  margin-bottom: 150px;
}